var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-container d-flex content align-items-center bg-custom-primary rounded"},[_c('ul',{staticClass:"nav navbar-nav d-xl-none"},[_c('li',{staticClass:"nav-item"},[_c('b-link',{staticClass:"nav-link",on:{"click":_vm.toggleVerticalMenuActive}},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"21"}})],1)],1)]),_c('div',{staticClass:"bookmark-wrapper align-items-center flex-grow-1",class:{ 'text-truncate': _vm.truncateText },style:(_vm.currentBreakPoint === 'xs' ? 'max-width:130px' : '')},[(_vm.project.id)?_c('span',{staticClass:"my-0 text-light h3 cursor-pointer",class:{
        h3: !_vm.isSmallScreen,
        h6: _vm.isSmallScreen,
        'cursor-pointer': _vm.isSmallScreen,
      }},[(_vm.currentUser.role_name === 'admin')?_c('span',[_vm._v(_vm._s(_vm.project.name))]):(_vm.validRoute)?_c('project-selector',{staticClass:"mx-1 mt-1",attrs:{"size":'md',"extra-class":'bg-primary text-light'}}):_vm._e()],1):_vm._e()]),_c('b-navbar-nav',{staticClass:"nav justify-content-between align-items-center ml-auto"},[_c('div',{staticClass:"mr-2"},[_c('b-button',{staticClass:"mr-1",class:{ 'custom-padding': _vm.isSmallScreen },attrs:{"size":"sm","variant":"outline-light"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"15"}})],1),_c('b-button',{class:{ 'custom-padding': _vm.isSmallScreen },attrs:{"size":"sm","variant":"outline-light"},on:{"click":function($event){return _vm.$router.go(1)}}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"15"}})],1)],1),(_vm.currentUser.name)?_c('b-nav-item-dropdown',{staticClass:"dropdown-user",attrs:{"right":"","toggle-class":"d-flex align-items-center dropdown-user-link"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-sm-flex d-none user-nav"},[_c('p',{staticClass:"user-name font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" ")]),_c('span',{staticClass:"user-status"},[_vm._v(_vm._s(_vm._f("roleName")(_vm.currentUser.role_name)))])]),_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"40","variant":"light-primary","badge":"","src":("" + (_vm.currentUser.logo)),"badge-variant":"success"}})]},proxy:true}],null,false,3617676818)},[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.$router.push({
            name: 'editCustomer',
            params: { customerId: _vm.currentUser.id },
          })}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"UserIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('profile')))])],1),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.logout()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }